import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Account } from '../models/account';
import { Guid } from '../models/guid';
import { ErrorService } from './error.service';
import { UserActivityService } from './user-activity.service';
import { AppPlatform } from '../models/app-platform';
import { TranslationService } from './translation.service';
import { WaitlistEntry } from '../models/waitlist-entry';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private accountSubject: BehaviorSubject<Account>;
  public account$: Observable<Account>;

  constructor(
    private http: HttpClient,
    private userActivityService: UserActivityService,
    private router: Router,
    private errorService: ErrorService,
    private tls: TranslationService
  ) {
    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account$ = this.accountSubject.asObservable();
  }

  private get baseUrl() {
    return `${environment.apiUrl}/auth`;
  }

  public get accountValue(): Account {
    return this.accountSubject.value;
  }

  public get hasPaidAccount(): boolean {
    if (!this.accountValue) return false;
    if (!this.accountValue.subscriptionEndsAt) return false;
    return new Date(this.accountValue.subscriptionEndsAt) > new Date();
  }

  public updateAccountSubject(account: Account) {
    this.accountSubject.next(account);
  }

  async refreshAccount() {
    const account = await this.getById(this.accountValue.id);
    // merge fields so we don't lose token data
    const joinedAccount = { ...this.accountValue, ...account };
    this.accountSubject.next(joinedAccount);
  }

  login(email: string, password: string) {
    console.log('login');
    return this.http
      .post<Account>(`${this.baseUrl}/authenticate`, { email, password }, { withCredentials: true })
      .pipe(
        tap(account => {
          this.accountSubject.next(account);
          this.saveRefreshToken(account.refreshToken);
        })
      )
      .toPromise();
  }

  async deleteAccount() {
    await this.http
      .post<any>(`${this.baseUrl}/delete-account`, {
        token: this.accountValue.refreshToken,
      })
      .toPromise();
    await this.logout();
    location.href = '/login';
  }

  async logout() {
    console.log('logout');
    let deviceToken: string = null;
    if (isPlatform('capacitor')) {
      try {
        const storageResult = await SecureStoragePlugin.get({
          key: 'deviceToken',
        });
        deviceToken = storageResult.value;
      } catch (err) {}
    }
    if (this.accountValue?.refreshToken) {
      console.log(`logout; revoke token: ${this.accountValue.refreshToken}, device token: ${deviceToken}`);
      try {
        await this.http
          .post<any>(
            `${this.baseUrl}/revoke-token`,
            {
              token: this.accountValue.refreshToken,
              deviceToken,
            },
            { withCredentials: true }
          )
          .toPromise();
      } catch (err) {}
    }
    await this.clearRefreshToken();
    try {
      await SecureStoragePlugin.remove({ key: 'deviceToken' });
    } catch (err) {}
    this.accountSubject.next(null);
    location.href = '/login';
  }

  async refreshToken(): Promise<Account> {
    console.log('refreshToken');
    const refreshToken =
      this.accountValue?.refreshToken ?? (await SecureStoragePlugin.get({ key: 'refreshToken' })).value;
    console.log('refreshToken with', refreshToken);
    if (!refreshToken) {
      this.accountSubject.next(null);
      return null;
    }

    return await this.http
      .post<any>(
        `${this.baseUrl}/refresh-token`,
        {
          refreshToken,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(
        map(async account => {
          this.accountSubject.next(account || null);
          this.saveRefreshToken(account?.refreshToken || null);
          return account || null;
        }),
        catchError(async err => {
          this.accountSubject.next(null);
          throw err;
        })
      )
      .toPromise();
  }

  async register(account: Account) {
    return this.http
      .post<Account>(`${this.baseUrl}/register`, account, {
        withCredentials: true,
      })
      .pipe(
        tap(account => {
          this.accountSubject.next(account);
          this.saveRefreshToken(account.refreshToken);
        })
      )
      .toPromise();
  }

  verifyEmail(token: string) {
    return this.http
      .post<Account>(`${this.baseUrl}/verify-email`, { token })
      .pipe(
        tap(account => {
          this.accountSubject.next(account);
          this.saveRefreshToken(account.refreshToken);
        })
      )
      .toPromise();
  }

  sendVerificationEmail() {
    return this.http.post(`${this.baseUrl}/resend-verification-email`, {}).toPromise();
  }

  forgotPassword(email: string) {
    return this.http.post(`${this.baseUrl}/forgot-password`, { email }).toPromise();
  }

  validateResetToken(token: string) {
    return this.http.post(`${this.baseUrl}/validate-reset-token`, { token }).toPromise();
  }

  resetPassword(token: string, password: string, confirmPassword: string) {
    return this.http
      .post(`${this.baseUrl}/reset-password`, {
        token,
        password,
        confirmPassword,
      })
      .toPromise();
  }

  getAll(limit: number, offset: number, search?: string) {
    return firstValueFrom(
      this.http.get<Account[]>(`${this.baseUrl}`, {
        params: {
          limit,
          offset,
          search,
        },
      })
    );
  }

  getById(id: Guid) {
    return this.http.get<Account>(`${this.baseUrl}/${id}`).toPromise();
  }

  getPublicById(id: Guid) {
    return this.http.get<Account>(`${this.baseUrl}/public/${id}`).toPromise();
  }

  create(params: Account) {
    return this.http.post(this.baseUrl, params).toPromise();
  }

  update(id: Guid, params: Partial<Account>) {
    return this.http
      .put(`${this.baseUrl}/${id}`, params)
      .pipe(
        map((account: any) => {
          // update the current account if it was updated
          if (account.id === this.accountValue.id) {
            // publish updated account to subscribers
            account = { ...this.accountValue, ...account };
            this.accountSubject.next(account);
          }
          return account;
        })
      )
      .toPromise();
  }

  delete(id: Guid) {
    return this.http
      .delete(`${this.baseUrl}/${id}`)
      .pipe(
        finalize(() => {
          // auto logout if the logged in account was deleted
          if (id === this.accountValue.id) this.logout();
        })
      )
      .toPromise();
  }

  sendPhoneCode(phone: string) {
    return this.http
      .post(`${this.baseUrl}/send-phone-code`, {
        id: this.accountValue.id,
        phone,
      })
      .toPromise();
  }

  verifyPhoneCode(code: string) {
    return this.http
      .post(`${this.baseUrl}/verify-phone-code`, {
        id: this.accountValue.id,
        code,
      })
      .toPromise();
  }

  async addDeviceToken(token: string) {
    await SecureStoragePlugin.set({
      key: 'deviceToken',
      value: token,
    });
    return await this.http
      .post(`${this.baseUrl}/device-token`, {
        token,
        type: isPlatform('ios') ? 1 : isPlatform('android') ? 2 : 0,
      })
      .toPromise();
  }

  async setAccountEmailPreference(email: string, doNotEmail: boolean) {
    return await this.http
      .post(`${this.baseUrl}/account-email-preference`, {
        email,
        doNotEmail,
      })
      .toPromise();
  }

  async joinWaitlist(request: any) {
    return await this.http.post(`${this.baseUrl}/join-waitlist`, request).toPromise();
  }

  async getWaitlistEntries(limit: number, offset: number) {
    return await this.http
      .get<WaitlistEntry[]>(`${this.baseUrl}/waitlist-entries?limit=${limit}&offset=${offset}`)
      .toPromise();
  }

  async updatePromptMetadata(accountId: Guid, promptMetadata: string) {
    return await this.http
      .put<WaitlistEntry[]>(`${this.baseUrl}/update-prompt-index`, {
        accountId,
        promptMetadata,
      })
      .toPromise();
  }

  getAcceptCallsFrom(account: Account): string[] {
    return JSON.parse(account.acceptCallsFrom);
  }

  // helper methods

  private async saveRefreshToken(token: string) {
    console.log('save refresh token', token);
    await SecureStoragePlugin.set({ key: 'refreshToken', value: token });
  }

  private async clearRefreshToken() {
    try {
      console.log('clear refresh token');
      await SecureStoragePlugin.remove({ key: 'refreshToken' });
    } catch (err) {}
  }
}
